import React, { useEffect, useState } from 'react';
import sol from './../../img/sol.png'
import TON from './../../img/TON.png'
import USDT from './../../img/USDT.png'
import BNB from './../../img/BNB.png'
import icono from './../../img/icono.png'

import Web3 from "web3";
import axios from "axios";

import ERC20Abi from "./../../abis/ERC20.json";
import SaleAbi from "./../../abis/presale.json";
const PresaleAddress = "0x53b8cFA5E23d5961eF03A03EDCc779B586D2b3C1";
const USDT_TOKEN = "0x55d398326f99059ff775485246999027b3197955"; //
const PresaleAbi = SaleAbi.abi;
const USDTabi = ERC20Abi.abi;


const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;
  
      let timeLeft = {};
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
      } else {
        timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
      return timeLeft;
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }, [targetDate]);
  
    return (
        <div className='items_countdown'>
        <div className='item_countdown'>
            <div>
                <span className='txt_days'>Days</span>
            </div>
            <div>
                <span className='txt_number'>{timeLeft.days}</span>
            </div>
        </div>
        <div className='item_countdown'>
            <div>
                <span className='txt_days'>Hours</span>
            </div>
            <div>
                <span className='txt_number'>{timeLeft.hours}</span>
            </div>
        </div>
        <div className='item_countdown'>
            <div>
                <span className='txt_days'>Minutes</span>
            </div>
            <div>
                <span className='txt_number'>{timeLeft.minutes}</span>
            </div>
        </div>
        <div className='item_countdown'>
            <div>
                <span className='txt_days'>Seconds</span>
            </div>
            <div>
                <span className='txt_number'>{timeLeft.seconds}</span>
            </div>
        </div>
    </div>

    );
  };
  
const CardPresale = () => {
    const [wallet_txt,setWallet_txt] = useState(null)
    const [Cantidad,setCantidad] =useState(0)
    const [msgModal,setModalMsg] = useState(null)
    const [priceToken, setPriceToken] = useState(null);
    const [compradoTotal, setTotalComprado] = useState(null);
    const [loading,setLoading] = useState(null)

    useEffect(()=>{
      if(wallet_txt){
        getComprado(wallet_txt)

      }
    },[wallet_txt])

    const walletInit = async () => {
        const { ethereum } = window;
        if (!ethereum) {
          console.log("Does not have metamask!");
          
          alert('NOT FOUND METAMASK')
    
        //   openNotification('bottomRight',"Does not have metamask!","Connect error")
    
          
          return;
        }
        try {
    
          
    
          ethereum.request({ method: "eth_requestAccounts" }).then(async (accs) => {
            console.log("found account!: " + accs[0]);
            var mainAddress = accs[0];
            console.log(mainAddress,'mainAddress')
                  console.log("Connected with Metamask. Account: " + accs[0])

            // let conectar = await cuentaService.SaveWallet(mainAddress);
            // if(conectar.data.insertId || conectar.data == 'own'){
    
            //   localStorage.setItem('wallet',mainAddress)
              setWallet_txt(mainAddress);
              setModalMsg({msg:'Wallet connected, buy now',active:true})

            //   dispatch(SetWallet(mainAddress))
            //   console.log("Connected with Metamask. Account: " + accs[0])
            // }
            // else{
            //   openNotification('bottomRight',"Error","It is not possible to purchase with this wallet, it has already been used previously in another account")
    
              // alert("It is not possible to purchase with this wallet, it has already been used previously in another account")
            // }
    
    
    
    
            // alert(accs[0])
          });
        } catch (err) {
          console.log("error");
          console.log(err);
        }
      };

      function convertToWei(etherAmount) {
        const web3 = new Web3();
        const weiAmount = web3.utils.toWei(etherAmount, 'ether');
        console.log(weiAmount,'wei')
        return weiAmount; // Retorna el valor en wei
      }
      
    
    
    const buyToken = async (val) => {
    
        if(wallet_txt === null){
          // alert("Conectar tu billetera primero")
          walletInit();
          // openNotification('bottomLeft', 'Error ', 'You must connect your wallet first')
    
          return
        }
        console.log("Processing..." + wallet_txt);
    
    
        try {
          const { ethereum } = window;
          setLoading(true)
          if (ethereum) {
            // WEB3 INSTANCE
            const web3 = new Web3(ethereum);
            // BUSD INSTANCE
            const token = new web3.eth.Contract(USDTabi, USDT_TOKEN);
            // PRESALE INSTANCE
            const presaleContract = new web3.eth.Contract(
              PresaleAbi,
              PresaleAddress
            );
            // AMOUNT TO BUY IN WEI
            //const amountTicket = web3.utils.toWei(val.toString(), "ether");
            //   console.log('position',position)
            let position = null;

              console.log(presaleContract.methods,'presaleContract.methods')
    
            // const maximo = await presaleContract.methods.getAmountMax(1).call();
    
            // setLoadingOverlay(true);
            // APROVE METHOD BUSD
            // await token.methods
            //   .approve(PresaleAddress,convertToWei(Cantidad))
            //   .send({ from: wallet_txt });
    
            console.log("PRICE TICKET: " + 10);
    
            // let amountBNB = feeBNB;
            // let feeBNB = await presaleContract.methods.getFeeBNB(1).call();
            // console.log("FEE BNB " + feeBNB);
    
            // BUY TOKENS WITH BUSD
            const txn = await presaleContract.methods
              .registerTonWallet(Cantidad)
              .send({  from: wallet_txt });
              setLoading(false)
              setModalMsg({msg:'Transaction completed '+txn.transactionHash,active:true})
              document.body.classList.remove('no-overflow');

            // console.log("RECARGA REALIZADA Hash>");
            // setLoadingOverlay(false);
            // alert('TRANSACCION COMPLETADA')
    
            // openNotification('bottomLeft', 'Success', 'Transaction completed')
            // handleOk()
    
            console.log(txn.transactionHash);
          }
          
        } catch (err) {
        setLoading(false)

        console.log(err);
        setModalMsg({msg:err.message,active:true})
        document.body.classList.remove('no-overflow');
        //   setLoadingOverlay(false);
        //   openNotification('bottomLeft', 'Error', err.message)
        }
    };

    useEffect(()=>{
        console.log(msgModal,'msgModalmsgModal')
        if(msgModal !== null){
            document.body.classList.add('modal-open');
        }
    },[msgModal])
    const targetDate = new Date(Date.UTC(2024, 8, 15, 20, 0, 0)); // Mes es 8 porque es 0-indexado

    // const targetDate = new Date(Date.UTC(0, 0, 0, 0, 0, 0)); // Mes es 8 porque es 0-indexado

    const getPrice = async () =>{
      setLoading(true)
      let price = await axios.get('https://bot-api.critterskombat.com/getPrice', {  });
      // let priceTON = await axios.get('https://bot-api.critterskombat.com/getPriceUSDT', {  });

      setPriceToken(parseFloat(price.data[0].price).toFixed(2))
      // setPriceTokenTON(parseFloat(priceTON.data[0].TON).toFixed(2))

      setLoading(false)
    }
    const getComprado = async (wallet) => {
      setLoading(true);
  
      try {
          // Realiza la solicitud a la API usando el valor de `wallet`
          const comprado = await axios.get(`https://bot-api.critterskombat.com/totalCoinsUSDT/${wallet}`);
  
          // Verifica que la respuesta contenga el campo `totalCoins`
          if (comprado.data && typeof comprado.data.totalCoins !== 'undefined') {
              setTotalComprado(parseFloat(comprado.data.totalCoins).toFixed(2));
          } else {
              console.error("El campo totalCoins no está presente en la respuesta", comprado.data);
              setTotalComprado(0);  // Valor por defecto en caso de que no exista `totalCoins`
          }
      } catch (error) {
          console.error("Error al obtener los datos:", error);
          setTotalComprado(0);  // Valor por defecto en caso de error
      } finally {
          setLoading(false);  // Asegúrate de que loading vuelva a ser false, tanto si hay error como si no
      }
  };
  
  

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;
  
      let timeLeft = {};
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
      } else {
        timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
      return timeLeft;
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }, [targetDate]);

    useEffect(()=>{
      setCantidad('')
      getPrice()
      
    },[])
    return (
        <>
        <div class={loading ? `wrap-loader active` : `wrap-loader`}>
        <span class="loader"></span>
        </div>
        <div id='modal_invite_blur' className={msgModal?.active ? 'active' : ''}></div>
            <div id='modal_invite' className={msgModal?.active? 'wrap_container active' : 'wrap_container'}>
                <div className='item-container-blue'>
                    <div className='title_modal_style1' style={{textWrap: "wrap"}}>
                        <p>{msgModal?.msg}</p>
                        <div className='cursor' >
                        </div>
                    </div>
                    <div className='btn_farming_dos' onClick={()=>{
                        setModalMsg(null)
                        document.body.classList.remove('modal-open');
                        document.body.classList.add('no-overflow');

                    }}>
                            <p>Close</p>
                    </div>
                 </div>
        </div>
        <div className='container'>
            
            
            <div className='container_card_presale'>
            <div className='icono_logo_presale'>
                <img src={icono} alt="" />
            </div>
                <div className='wrap_title_presale'>
                    
                    <h2>CBT Token Pre-sale</h2>
                </div>
                <CountdownTimer targetDate={targetDate} />
                <div className='countdown_presale'>
                    
                    <div className='bar_presale'>
                        <div className='bar_presale_lleno'></div>
                    </div>
                    <div>
                        <p className='txt_white'>Buy before next stage price increase</p>
                    </div>
                </div>
                <div className='token_value_presale'>
                    <div className='bar_hr'></div>
                    <div className=''>
                        <p className='price_token'>1 $CBT = $ {priceToken}</p>
                    </div>
                    <div className='bar_hr'></div>
                </div>
                <div className='list_monedas'>
                    {/* <div className='item_moneda active'>
                        <img width={18} src={sol} alt="" />
                        <span className='txt_gris'>SOL</span>
                    </div>
                    <div className='item_moneda'>
                        <img width={18} src={TON} alt="" />
                        <span className='txt_gris'>TON</span>
                    </div>
                    <div className='item_moneda'>
                        <img width={18} src={BNB} alt="" />
                        <span className='txt_gris'>BNB</span>
                    </div> */}
                    <div className='item_moneda'>
                        <img width={18} src={USDT} alt="" />
                        <span className='txt_gris'>USDT</span>
                    </div>
                </div>
                <div className='buy_receive'>
                    <div>
                        <p className='buy_title'>May I kindly ask you to share your TON wallet address for the transaction?</p>
                        <input onChange={(e)=>setCantidad(e.target.value)} value={Cantidad} className='input_presale' type="text" />
                    </div>
                    {/* <div>
                        <p className='buy_title'>Receive $CBT</p>
                       
                        {
                          priceToken && <input value={Cantidad/parseFloat(priceToken)} readOnly className='input_presale' type="number" />
                        }
                    </div> */}
                </div>
                {/* <div className='info_token_buy'>
                    <div className='info_token_buy_wrap'>
                        <div className='txt_white token_comprado_en'>
                            <img src={USDT} alt="" /> Token bought on USDT
                        </div>
                        <div className='txt_white'>
                        {compradoTotal}
                        </div>
                    </div>
                </div> */}
                {
                  timeLeft.days  == 0 &&
                  timeLeft.hours == 0 &&
                  timeLeft.minutes == 0 &&
                  timeLeft.seconds == 0 
                  ?
                  <>
                  <div class="btn-buy" onClick={()=>buyToken()}>
                    <p>
                      {
                        wallet_txt ? 'Generate Transaction' : 'Connect Wallet'
                      }
                    </p>
                  </div>

                  
                  <div className='btns_two_wrap'>
                      <div className='btn_two_gris'>
                          <p>Calim</p>
                      </div>
                      <div className='btn_two_azul'>
                          <p>Stake Now</p>
                      </div>
                  </div>
                  </>: null
                }
                <hr />

                <p className='aceptar_terminos'>
                By pressing I agree, that I have read in full and accept the Terms and Conditions and Accompanying Documents in theirs entirety. I'm 18 years old and not a resident of Prohibited Jurisdictions.
                </p>
            </div>
        </div>
        </>
        
    );
};

export default CardPresale;